.container {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(8px);
    background: linear-gradient(
        53deg,
        rgba(16, 6, 43, 0.6),
        rgba(112, 63, 138, 0.6)
    );
    border-radius: 0.5rem;
    padding: 10px;
}