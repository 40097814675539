.container {
    backdrop-filter: blur(10px); // Размывает контент под navbar
    -webkit-backdrop-filter: blur(8px); // Поддержка для Safari
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    background: linear-gradient(
        53deg,
        rgba(201, 123, 243, 0.3),
        rgba(16, 6, 43, 0.3)
    );
}

.auth {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-content: stretch;
    flex-direction: column;
    gap: 1rem;

    width: 100%;
    padding: 10px;
    background: linear-gradient(
        53deg,
        rgba(201, 123, 243, 0.1),
        rgba(0, 102, 255, 0.2)
    );
    border-radius: 1rem;
}
