@import "../../styles/mixins.scss";


.pricing {
    padding: 4rem 0;

    .pricing_description {
        padding: 1rem 0rem 2rem 0;
        font-size: 1.2rem;
        text-align: center;
        color: var(--light-gray);
    }

    .tariffs_container {
        display: flex;
        justify-content: space-between;
        gap: 1.5rem;
    }

    @media (max-width: 768px) {
        padding: 2rem 0;

        .pricing_description {
            font-size: 1rem;
        }

        .tariffs_container {
            flex-direction: column;
            align-items: center;
            gap: 1rem;
            padding-bottom: 2rem;
        }
    }

    @media (max-width: 480px) {
        padding: 1rem 0;

        .pricing_description {
            font-size: 0.9rem;
        }

        .tariffs_container {
            gap: 0.5rem;
        }
    }
}
