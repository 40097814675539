@import "../../../styles/mixins.scss";

.generate_text_models_item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
    background: linear-gradient(53deg, rgba(201, 123, 243, 0.1), rgba(16, 6, 43, 0.1));
    border-radius: 0.5rem;
    width: 15rem; /* Фиксированная ширина */
    object-fit: contain;
    cursor: pointer;
    transition: transform 0.3s, filter 0.3s;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(8px);

    &:hover {
        transform: scale(1.02);
        filter: brightness(1.2);
    }

    img {
        height: 4.5rem;
    }

    .model_item {
        width: 5rem;
        text-align: center;
        font-size: 1.3rem;
    }

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 0.5rem;
        width: 12rem; /* Фиксированная ширина для мобильных устройств */
        img {
            height: 3rem;
        }
        .model_item {
            width: auto;
            font-size: 1rem;
        }
    }

    @media (max-width: 480px) {
        flex-direction: column;
        gap: 0.5rem;
        width: 10rem; /* Фиксированная ширина для маленьких экранов */
        img {
            height: 2.5rem;
        }
        .model_item {
            width: auto;
            font-size: 0.9rem;
        }
    }
}

.additional_info {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    width: 100%;
    margin-top: 1rem;
    z-index: 1;

    .icon {
        font-size: 2rem;
    }

    .description {
        font-size: 1.2rem;
        background: var(--black);
        border-radius: 0.5rem;
        padding: 0.5rem;
        text-align: center;
        width: 100%;
    }

    @media (max-width: 768px) {
        .description {
            font-size: 1rem;
        }
    }

    @media (max-width: 480px) {
        .description {
            font-size: 0.9rem;
        }
    }
}

.generate_text_models_item:hover + .additional_info {
    visibility: visible;
    opacity: 1;
    transform: translateX(-50%) translateY(0);
}

:global(.popover) {
    color: white;
    background: linear-gradient(45deg, rgb(0, 104, 255), rgb(201, 123, 243));
    border: 0px solid rgba(0, 0, 0, 0);
    border-radius: 0.5rem;

    .body {
        color: white;
    }
}