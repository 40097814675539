.button_container {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    margin-top: 0.4rem;
    padding: 0.4rem;
    background: linear-gradient(45deg, rgb(0, 104, 255), rgb(201, 123, 243));
    border-radius: 1rem;

    @media screen and (max-width: 991px) {
        flex-direction: column;
        gap: 1rem;
    }

    @media screen and (max-width: 576px) {
        max-width: 70%;
        margin: 0 auto;
    }

    @media screen and (max-width: 400px) {
        max-width: 100%;
        
    }


    .button_download {
        width: 100%;
        padding: 0px;
        background-color: transparent;
        cursor: pointer;
        border-radius: 1rem;
        overflow: hidden;

        &:hover {
            color: var(--almost-white);
            opacity: 0.8;
            filter: drop-shadow(0 0 0.5rem var(--light-purple));           
        }

    }
}