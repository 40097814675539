.container {
    padding: 5rem 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    h3 {
        font-size: 1.2rem;
        padding-bottom: 2rem;
        font-weight: 600;
    }

    .menu {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .menu_item {
            padding: 1rem;
            background: linear-gradient(
                53deg,
                rgba(201, 123, 243, 0.1),
                rgba(16, 6, 43, 0.1)
            );
            border-radius: 0.5rem;
            cursor: pointer;
            transition: filter 0.3s;
            color: var(--almost-white);

            &:hover {
                filter: brightness(1.5);
            }
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 2.5rem;

        .content_item {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            a {
                color: var(--almost-white);
                transition: all 0.3s ease;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                    color: var(--light-gray);
                }
            }
        }
    }

    .scrollTop {
        position: fixed;
        right: 20px;
        bottom: 20px;
        height: 50px;
        width: 50px;
        padding: 0.5rem;
        background: linear-gradient(53deg, rgba(0, 102, 255, 0.2), rgba(201, 123, 243, 0.6));
        color: white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        z-index: 1000;
        transition: opacity 0.4s, visibility 0.4s;
        opacity: 0.8;
    }
    
    .scrollTop:hover {
        opacity: 1;
    }
}
