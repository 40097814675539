@import "../../../styles/mixins.scss";

.dropdown {
    line-height: 0rem;

    .language_container {
        min-width: 1.4rem;
        min-height: 1.4rem;
        background: none;
        text-transform: uppercase;
        padding: 0.2rem 0.2rem;

        font-size: 0.7rem;

        color: var(--almost-white);
        border: 1px solid var(--light-purple);
        border-radius: 0.3rem;
        cursor: pointer;
        transition: all 0.3s ease;
        text-align: center;

        &:hover {
            background: var(--light-purple);
            border: 1px solid var(--light-purple);
        }

        &.active {
            background: var(--light-purple);
            border: 1px solid var(--light-purple);
        }

        &.footer {
            text-transform: none;
            // padding: 0.2rem 0.2rem 1rem 0.2rem;
            padding: 0;
            font-size: 1rem;
            border: 1px solid var(--black);

            &:hover {
                background: var(--black);
                border: 1px solid var(--black);
                color: var(--light-purple);
            }

            @media screen and (max-width: 991px) {
                line-height: 1rem;
            }
        }

        &::after {
            display: none;
        }
    }

    .dropdown_menu {
        line-height: 1.5rem;
        margin-top: 14px;
        border-radius: 0rem 0rem 0.5rem 0.5rem;
        // border: 1px solid var(--light-purple);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(8px);
        background: linear-gradient(
            53deg,
            rgba(16, 6, 43, 0.6),
            rgba(112, 63, 138, 0.6)
        );
        //   border: 1px solid rgba(201, 123, 243, 0.1);

        .dropdown_item {
            background-color: transparent;
            color: var(--almost-white);
            cursor: pointer;
            padding: 0.2rem 1rem;
            transition: color 0.3s ease;
            border: 1px solid transparent;

            &:hover {
                // border: 1px solid var(--light-purple);
                color: var(--light-purple);
            }

            &.active {
                color: var(--light-purple);
                // подчеркивание под текстом
                // text-decoration: underline;
                // text-decoration-color: var(--light-purple);
                // text-underline-offset: 0.4rem;
                // text-decoration-thickness: 0.12rem;
            }
        }
    }
}
