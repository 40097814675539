@import "../../styles/mixins.scss";

.container {
    padding-top: 5rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    div {
        padding-bottom: 2rem;

        h3 {
            font-size: 1.2rem;
            padding-bottom: 1rem;
            font-weight: 600;
        }

        p {
            font-size: 1.1rem;
            padding-bottom: 0.5rem;
        }

        ul {
            padding-left: 2rem;
            padding-bottom: 1rem;

            li {
                list-style: disc;
                
                a {
                    color: var(--almost-white);
                    transition: all 0.3s ease;

                    &:hover {
                        color: var(--light-gray);
                    }
                }
            }
        }
    }
}