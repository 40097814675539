@import "../../styles/mixins.scss";

.container {
    padding-top: 5rem;
    padding-bottom: 5rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    h3 {
        font-size: 1.2rem;
        padding-bottom: 1rem;
        font-weight: 600;
    }

    .accordion_container {
        border-radius: 0.5rem;

        .accordion_item {
            backdrop-filter: blur(10px);
            -webkit-backdrop-filter: blur(8px);
            background: linear-gradient(
                53deg,
                rgba(201, 123, 243, 0.1),
                rgba(16, 6, 43, 0.1)
            );
            border: 1px solid rgba(201, 123, 243, 0.1);
            color: var(--almost-white);

            button {
                backdrop-filter: blur(10px);
                -webkit-backdrop-filter: blur(8px);
                background: linear-gradient(
                    53deg,
                    rgba(201, 123, 243, 0.1),
                    rgba(16, 6, 43, 0.1)
                );
                border: 1px solid rgba(201, 123, 243, 0.1);
                color: var(--almost-white);
            }
        }
    }
}
