@import "../../styles/mixins.scss";

.content {
    padding-top: 4rem;

    .content_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4rem;

        .content_container_item {
            display: flex;
            flex-direction: column;
            align-items: center;

            .generate_text_description {
                max-width: 45rem;
                padding-top: 1rem;
                font-size: 1.2rem;
                text-align: center;
                color: var(--light-gray);
            }

            .generate_models {
                display: flex;
                align-items: center;
                gap: 1rem;
                padding: 2rem 0;
                flex-wrap: wrap; /* Добавляем для переноса на следующую строку */
                justify-content: center; /* Центрируем элементы */
            }
        }
    }

    @media (max-width: 768px) {
        padding-top: 2rem;

        .content_container {
            gap: 2rem;

            .content_container_item {
                .generate_text_description {
                    font-size: 1rem;
                    padding-top: 0.5rem;
                }

                .generate_models {
                    // gap: 0.5rem;
                    padding: 1rem 0;
                }
            }
        }
    }

    @media (max-width: 480px) {
        padding-top: 1rem;

        .content_container {
            gap: 1rem;

            .content_container_item {
                .generate_text_description {
                    font-size: 0.9rem;
                    padding-top: 0.5rem;
                }

                .generate_models {
                    // gap: 0.5rem;
                    padding: 1rem 0;
                }
            }
        }
    }
}