.accordion_item {
    background: transparent;
    border: 1px solid var(--light-gray);

    .item_body {
        color: var(--almost-white);
    }
}

.accordion_item:first-child {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.accordion_item:nth-child(2) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.accordion_item:nth-child(3) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.accordion_item:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}

.custom_toggle_container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .custom_toggle {
        width: 100%;
        border: none;
        background: transparent;
        color: white;
        text-align: left;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
    }
    
    .toggle_content {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }
    
    .always_active {
        color: var(--almost-white);
        text-align: right;
    }
    
    .form {
        .form_switch input {
            cursor: pointer;
            margin-left: 0;
        }
    }
}
