.bidBtn {
    margin-top: 3rem;
    padding: 1rem!important;
    font-size: 1.3rem!important;
    font-weight: bold;
    border-radius: 6rem;
    background: linear-gradient(45deg, #d100d1, #c97bf3)!important;
    color: #5a005a;
    border: 1px solid transparent!important;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 0 15px 5px rgba(209, 0, 209, 0.1), 
                    0 0 20px 10px rgba(201, 123, 243, 0.1); 
    &:hover {
        border: 1px solid transparent;
        background: linear-gradient(53deg, #d100d1, #c97bf3);
        color: var(--almost-white);
        box-shadow: 0 0 18px 8px rgba(209, 0, 209, 0.3), 
                    0 0 24px 14px rgba(201, 123, 243, 0.3); 
    }

    @media screen and (max-width: 576px) {
        margin-top: 1rem;
        padding: 10rem;
    }
}