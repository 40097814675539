@import "../../styles/mixins.scss";

.main {
    padding-top: 3rem;

    @media screen and (max-width: 576px) {
        padding-top: 6rem;
    }

    .main_content {
        display: flex;
        // justify-content: center;
        align-items: center;


        .main_title {
            font-size: 2.5rem;
            font-weight: bold;
            text-transform: uppercase;
    
            @include respond(phone) {
                font-size: 2.4rem;
            }
        }
    
        .spanH1 {
            color: var(--light-purple);
        }
    
        .description {
            margin-top: 3rem;
            font-size: 1.2rem;
        }
    
        
    

    }

    .main_text_content {
        padding-top: 3rem;

        .main_text {
            min-height: 150px;
            backdrop-filter: blur(10px); // Размывает контент под navbar
            -webkit-backdrop-filter: blur(8px); // Поддержка для Safari
            background:linear-gradient(53deg, rgba(201, 123, 243, 0.1), rgba(16, 6, 43, 0.1)); // затемнение
            border: 1px solid rgba(201, 123, 243, 0.1);
            padding: 1rem;
            font-size: 1.2rem;
            border-radius: 0.5rem;
            transition: all 0.3s ease-in-out;

            @media screen and (max-width: 576px) {
                margin-bottom: 1rem;
            }

            // &:hover {
            //     // background:linear-gradient(73deg, rgba(201, 123, 243, 0.2), rgba(16, 6, 43, 0.2)); // затемнение
            //     // box-shadow: 0 0 8px 4px rgba(144, 89, 177, 0.3), 0 0 24px 14px rgba(16, 6, 43, 0.3);  
            // }
        }
    }

    .scroll_container {
        position: absolute;
        top: 100px;
        right: 32px;

        @media screen and (max-width: 576px) {
            display: none;
        }

        @media screen and (max-width: 999px) {
            right: 10px;
        }

        .scroll_down {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-top: 6rem;
            cursor: pointer;
     
    
    
            .see_more {
                text-transform: uppercase;
                writing-mode: vertical-rl;
                padding-bottom: 1rem;
            }
    
            .arrow_icon {
                font-size: 1.6rem;
                animation: moveUpDown 3.5s ease-in-out infinite;
    
            }
        }
    }
}

@keyframes moveUpDown {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}