.form_group {
    margin-bottom: 1rem;
}

.input_control {
    background-color: rgba(16, 6, 43, 0.5);
    border: 1px solid rgba(16, 6, 43, 0.8);
    color: white;
    &:focus {
        background-color: rgba(16, 6, 43, 0.5) ;
        border: 1px solid rgba(16, 6, 43, 0.8);
        color: white;
    }

}

.input_control::placeholder {
    color: #9a66b7 ;
}

.input_label {}

