.container {
    backdrop-filter: blur(10px); // Размывает контент под navbar
    -webkit-backdrop-filter: blur(8px); // Поддержка для Safari
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    background: linear-gradient(
        53deg,
        rgba(201, 123, 243, 0.3),
        rgba(16, 6, 43, 0.3)
    );
    overflow-y: auto;

    .container_body {
        display: flex;
        flex-direction: column;

        h3 {
            color: var(--almost-white);
            font-weight: 600;
            font-size: 1.1rem;
            padding-bottom: 1rem;
        }
    
        .text {
            color: var(--almost-white);
            font-size: 0.9rem;
            padding-bottom: 1rem;
        }
    
        .link {
            color: var(--almost-white);
            text-decoration: underline;
            transition: all 0.3s ease;
    
            &:hover {
                text-decoration: none;
            }
        }
    
        .button_allow {
            margin-bottom: 1.5rem;
        }

        .buttons_actions {
            display: flex;
            justify-content: space-between;
            gap: 1rem;
            padding-top: 1.5rem;

            .button_reject {
                width: 50%;
            }
        }
    }

}

/* Кастомный скролл */
.container_body::-webkit-scrollbar {
    width: 10px; /* Width of the scrollbar */
}

.container_body::-webkit-scrollbar-track {
    background: var(--almost-white); /* Background of the scrollbar track */
    border-radius: 10px;
}

.container_body::-webkit-scrollbar-thumb {
    background: var(--light-gray); /* Color of the scrollbar thumb */
    border-radius: 10px;
}

.container_body::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color when hovering over the scrollbar thumb */
}
