@import "../../../styles/mixins.scss";

.login_button {
    background: transparent!important;
    color: var(--almost-white)!important;
    text-decoration: none;
    cursor: pointer;
    font-size: 1.1rem;
    transition: all 0.3s ease;
    display: inline-block;

    &:hover {
        color: var(--light-purple);
    }
}

// .modal_header {
//     background-color: var(--black);
//     border: 1px solid var(--light-purple);
//     border-bottom: 1px solid rgba(255, 255, 255, 0.1);

//     .modal_title {
//         color: var(--almost-white);
//     }
// }

// .modal_body {
//     background-color: var(--black);
//     border: 1px solid var(--light-purple);
//     border-bottom: 1px solid rgba(255, 255, 255, 0.1);
//     border-top: none;

//     .modal_form {
//         display: flex;
//         flex-direction: column;
//         gap: 1rem;
//         background-color: var(--black);
    
//         .modal_label {
//             color: var(--almost-white);
//         }
//     }
// }

// .modal_footer {
//     background-color: var(--black);
//     border: 1px solid var(--light-purple);
//     border-top: 1px solid rgba(255, 255, 255, 0.1);
// }