@import "../../styles/mixins.scss";

.navbar {
    backdrop-filter: blur(10px); // Размывает контент под navbar
    -webkit-backdrop-filter: blur(8px); // Поддержка для Safari
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    .burger_and_logo {
        display: flex;
        // justify-content: flex-start;
        align-items: center;
        margin-right: 40px;

        @media screen and (max-width: 1000px) {
            margin-right: 0;
        }

 
    }
    .navbar_logo_container {
        display: flex;
        gap: 1rem;

        .navbar_logo {
            text-transform: uppercase;
            font-size: 1.2rem;
            font-weight: 600;
            color: var(--almost-white);

            
        }
    }

    .menu_block {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        @media screen and (max-width: 1000px) {
            width: auto;
            justify-content: flex-end;
        }

        @media screen and (max-width: 576px) {
            width: 60px;
        }
            

    }

    .auth_buttons_container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 1rem;

        @media (max-width: 1000px) {
            flex-direction: row;
            align-items: center;
            // gap: 1rem;
            width: 100%;

            &.open {
                display: flex;
            }
        }
    }

    .mobile_menu_button {
        display: none;
        background: linear-gradient(
            53deg,
            rgba(201, 123, 243, 0.1),
            rgba(0, 102, 255, 0.2)
        );
        border: 1px solid transparent;
        font-size: 1.1rem;
        color: var(--almost-white);
        width: 2.5rem;
        height: 2.5rem;
        cursor: pointer;

        &:focus {
            outline: none;
        }

        @media (max-width: 1000px) {
            display: block;
        }
    }

    .menu {
        display: block;

        @media (max-width: 1000px) {
            display: none;
        }
    }
    .auth {
        display: block;

        @media (max-width: 576px) {
            display: none;
        }
    }
}
