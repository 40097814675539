// .button_container {
//     display: flex;
//     justify-content: space-between;

//     @media screen and (max-width: 991px) {
//         flex-direction: column;
//         gap: 1rem;
//     }

//     .button_download {
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         padding: 1rem;
//         gap: 0.5rem;
//         font-size: 1.2rem;
//         letter-spacing: 1px;
//         border-radius: 0.5rem;
//         background-color: var(--almost-white);
//         cursor: pointer;

//         &:hover {
//             background: var(--light-purple);
//             color: var(--almost-white);

//             .icon {
//                 color: var(--almost-white);
//             }
//         }

//         .icon {
//             display: flex;
//             font-size: 2.3rem;
//             color: var(--black);
//         }

//         .text_container {
//             display: flex;
//             flex-direction: column;
//             font-size: 1rem;

//             .span {
//                 font-weight: 800;
//             }
//         }

//         @media screen and (max-width: 1200px) {
//             letter-spacing: 0;
//             padding: 0.5rem;
//         }
//     }
// }

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media screen and (max-width: 576px) {
        display: none;
    }
}

// .container {
//     max-width: 100%;
//     @media screen and (max-width: 576px) {
//         max-width: 70px;       
//     }
// }