.banner {
    position: fixed;
    bottom: 15px;
    right: 15px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    max-width: 700px;
    box-sizing: border-box;
    background: linear-gradient(
        53deg,
        rgba(201, 123, 243, 0.6),
        rgba(16, 6, 43, 0.6)
    );
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(8px);
    border-radius: 0.5rem;
    z-index: 999;

    @media screen and (max-width: 767px) {
        max-width: 70%;
        left: 10px;
        right: 10px;
        bottom: 10px;
    }

    .without_accepting {
        font-size: 0.8rem;
        text-decoration: underline;
        cursor: pointer;
        text-align: right;
        transition: all 0.3s ease;
        padding-bottom: 0.3rem;

        &:hover {
            text-decoration: none;
        }
    }

    h4 {
        font-size: 1.1rem;
        font-weight: 600;
        padding-bottom: 1rem;
    }

    .text {
        font-size: 0.8rem;
        padding-bottom: 1rem;

        .link {
            color: wheat;
            padding-left: 0.3rem;
            text-decoration: underline;
            transition: all 0.3s ease;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .buttons_container {
        display: flex;
        justify-content: space-between;

        .buttons_actions {
            display: flex;
            gap: 1rem;
        }
    }
}

.bannerLeft {
    @extend .banner;
    right: auto;
    left: 15px;
}