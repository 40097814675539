.reviews {
    padding-bottom: 4rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    .reviews_description {
        padding: 1rem 0rem 2rem 0;
        font-size: 1.2rem;
        text-align: center;
        color: var(--light-gray);
    }
}