.tariff_item {
    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;
    cursor: pointer;
    border: 1px solid rgba(201, 123, 243, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;

    &.free {
        &:hover {
            transform: scale(1.02);
            box-shadow: 0 0 14px 4px rgba(201, 123, 243, 0.1),
                0 0 20px 10px rgba(201, 123, 243, 0.1);
        }
    }

    &.pro {
        &:hover {
            transform: scale(1.02);
            box-shadow: 0 0 14px 4px rgba(16, 6, 243, 0.1),
                0 0 20px 10px rgba(123, 213, 243, 0.1);
        }
    }

    &.pro_plus {
        &:hover {
            transform: scale(1.02);
            box-shadow: 0 0 14px 4px rgba(112, 63, 138, 0.1),
                0 0 20px 10px rgba(112, 63, 138, 0.1);
        }
    }


    .tariff_header {
        display: flex;
        flex-direction: column;
        padding: 1.5rem;
        width: 100%;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        &.free_header {
            background: linear-gradient(
                53deg,
                rgba(201, 123, 243, 0.1),
                rgba(0, 102, 255, 0.2)
            );
        }

        &.pro_header {
            background: linear-gradient(
                53deg,
                rgba(0, 102, 255, 0.2),
                rgba(201, 123, 243, 0.6)
            );
        }

        &.pro_plus_header {
            background: linear-gradient(
                53deg,
                rgba(201, 123, 243, 0.6),
                rgba(16, 6, 43, 0.6)
            );
        }

        .tariff_item_title {
            font-size: 1.5rem;
            text-transform: capitalize;
            font-weight: 600;
        }

        .tariff_item_description {
            padding-top: 0.5rem;
            max-width: 20rem;
        }

        .tariff_item_price {
            font-size: 1.2rem;
            padding: 2rem 0;

            .price {
                font-size: 1.5rem;
                font-weight: 600;
            }
        }
        .button {
            border-radius: 2.5rem;
            background-color: inherit;
            border: 1px solid white;
            color: white;

            &:hover {
                background: linear-gradient(45deg, rgba(209, 0, 209, 0.7), rgba(201, 123, 243, 0.7));
                border: 1px solid rgba(201, 123, 243, 0.2);
            }
        }
    }

    .models_container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        background-color: var(--black);
        padding: 1.5rem;
        border-radius: 0.5rem;

        .item_container {
            display: flex;
            align-items: center;
            gap: 1rem;

            .icon {
                color: var(--light-purple);
                font-size: 1.5rem;
            }

            .text_container {
                display: flex;
                flex-direction: column;
                gap: 0.1rem;

                .total {
                    font-weight: 600;
                }

                .model {
                    color: var(--light-gray);
                }
            }
        }
    }
}
