@import "../../styles/mixins.scss";

.footer {
    padding: 1.5rem 0 3rem 0;

    h3 {
        font-size: 1.2rem;
        text-transform: uppercase;
        padding-bottom: 1rem;
    }

    .footer_block_list {
        display: flex;
        flex-direction: column;
        gap: 0.3rem;

        @media screen and (max-width: 767px) {
            padding-bottom: 1.5rem;
        }

        .footer_list_item {
            color: var(--light-gray);
            cursor: pointer;
            transition: all 0.3s ease;

            &:hover {
                color: var(--light-purple);
            }

            a {
                color: var(--light-gray);
                cursor: pointer;
                transition: all 0.3s ease;

                &:hover {
                    color: var(--light-purple);
                }
            }
        }
    }

    .footer_copyright {
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        @media screen and (max-width: 991px) {
            flex-direction: column;
            gap: 1.5rem;
            padding-top: 1.5rem;
        }

        .copyright_text {
            text-align: right;
            text-transform: uppercase;
            padding-bottom: 1rem;
        }

        .social_icons {
            display: flex;
            justify-content: flex-end;
            gap: 1rem;

            @media screen and (max-width: 991px) {
                justify-content: flex-start;
            }

            .social_icon {
                font-size: 1.5rem;
                cursor: pointer;
                transition: all 0.3s ease;

                &:hover {
                    color: var(--light-purple);
                }
            }
        }
    }
}
