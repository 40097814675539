.container {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(8px);
    background: linear-gradient(
        53deg,
        rgba(16, 6, 43, 0.6),
        rgba(112, 63, 138, 0.6)
    );
}
.modal_body {
}
.modal_header {
    
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.modal_footer {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}


