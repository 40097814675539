.container {
    width: 100%;
    padding: 5rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    .container_content {
        display: flex;
        flex-direction: column;
        max-width: 500px;
        border-radius: 0.5rem;
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(8px);
        background: linear-gradient(53deg, rgba(201, 123, 243, 0.1), rgba(16, 6, 43, 0.1));
        border: 1px solid rgba(201, 123, 243, 0.1);
        padding: 1rem;

        h3 {
            font-size: 1.2rem;
            padding-bottom: 1rem;
            font-weight: 600;
        }

        .modal_form {
            padding: 1.5rem 0;

            .your_message_control {
                background-color: rgba(16, 6, 43, 0.5);
                border: 1px solid rgba(16, 6, 43, 0.8);
                color: var(--almost-white);

                &:focus {
                    background-color: rgba(16, 6, 43, 0.5) ;
                    border: 1px solid rgba(16, 6, 43, 0.8);
                    color: var(--almost-white);
                }
            }
        }

        .button {
            border-radius: 0.5rem;
            background-color: inherit;
            border: 1px solid white;
            color: white;
            transition: all 0.3s ease;

            &:hover {
                background: linear-gradient(45deg, rgba(209, 0, 209, 0.7), rgba(201, 123, 243, 0.7));
                border: 1px solid rgba(201, 123, 243, 0.2);
            }
        }
    }
}