@import "./styles/mixins.scss";

:root {
    --almost-white: #f6f6f6;
    --light-purple: #c97bf3;
    --light-gray: #b1b1b1;
    --black: #10062b;
    --bright-blue: #0066ff;
    --gradient: linear-gradient(to right, #c97bf3, #e6b8fc);
}

* {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    outline: none;
    list-style: none;
}

html {
    scroll-behavior: smooth;
}

body {
    box-sizing: border-box;
    overflow-x: hidden;
    line-height: 1.5;
    font-family: "Roboto", sans-serif;
    color: var(--almost-white);
    background-color: var(--black);
}

a {
    text-decoration: none;
}

ul,
p,
h1,
h2,
h3,
h4,
h5 {
    margin: 0;
    padding: 0;
}

h2 {
    text-align: center;
    font-size: 2rem;
}

.btn {
    background: var(--almost-white);
    // padding: 0.6rem 1.2rem;
    color: var(--black);
    // border-radius: 0.5rem;
    cursor: pointer;
    font-size: 1.1rem;
    transition: all 0.3s ease;
    border: 0px solid var(--light-purple);

    &:hover {
        background: var(--light-purple);
        color: var(--almost-white);
    }

    @include respond(phone) {
        padding: 0.2rem 0.2rem;
    }
}

.main {
    background-image: url("./assets/wave_01.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-size: 100%;
}

.content {
    background-image: url("./assets/wave_02.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-size: 100%;
}

/* Для слайдера */
.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    font-size: 1.2rem;
    background: linear-gradient(53deg, rgba(201, 123, 243, 0.1), rgba(16, 6, 43, 0.1));
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(8px);
    border-radius: 0.5rem;
    padding: 1rem;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
/* Для слайдера */