.container {
    width: 100%;
    padding: 5rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    .container_content {
        display: flex;
        flex-direction: column;
        max-width: 500px;
        border-radius: 0.5rem;
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(8px);
        background: linear-gradient(53deg, rgba(201, 123, 243, 0.1), rgba(16, 6, 43, 0.1));
        border: 1px solid rgba(201, 123, 243, 0.1);
        padding: 1rem;

        p {
            text-align: center;
            font-size: 1.1rem;
            color: var(--light-gray);

            .span {
                color: var(--light-purple);
                cursor: pointer;
                transition: all 0.3s ease;
    
                &:hover {
                    color: var(--almost-white);
                }
            }
        }

        .modal_form {
            padding: 1.5rem 0;
        }

        .button {
            border-radius: 0.5rem;
            background-color: inherit;
            border: 1px solid white;
            color: white;
            transition: all 0.3s ease;
            margin-bottom: 2rem;

            &:hover {
                background: linear-gradient(45deg, rgba(209, 0, 209, 0.7), rgba(201, 123, 243, 0.7));
                border: 1px solid rgba(201, 123, 243, 0.2);
            }
        }

        .approval {
            text-align: left;
            font-size: 0.9rem;
            color: var(--light-gray);
        }
    }
}