@import "../../../styles/mixins.scss";

.carousel_item {
    display: flex;
    justify-content: center;
    padding-bottom: 2rem;
}


.slider_container {
    position: relative;
  }
  
  .parallax_wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  
    .parallax_item {
      position: absolute;
      width: 18%;
      height: auto;
      transition: transform 0.5s ease-out;
  
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      &:nth-child(1) {
        top: 10%;
        left: 5%;
        rotate: -15deg;
        z-index: 1;
      }
  
      &:nth-child(2) {
        top: 40%;
        right: 2%;
        z-index: 2;
      }
  
      &:nth-child(3) {
        bottom: 10%;
        left: 18%;
        rotate: 15deg;
        z-index: 2;
      }
    }
  }

  .circle {
    position: absolute;
    backdrop-filter: blur(10px); 
    -webkit-backdrop-filter: blur(8px); 
    border-radius: 50%;

    // animation: glow 3s ease-in-out infinite;
  
    &.circle1 {
      width: 1px;
      height: 1px;
      background: radial-gradient(circle, rgb(0, 102, 255), rgba(0, 102, 255, 0.758));
      box-shadow: 0 0 230px 120px rgba(0, 102, 255,0.758);
      top: 20%;
      left: 30%;
    }
  
    &.circle2 {
      width: 1px;
      height: 1px;
      background: radial-gradient(circle, rgb(201, 123, 243), rgba(201, 123, 243, 0.758));
      box-shadow: 0 0 200px 100px rgba(201, 123, 243,0.758);
      bottom: 36%;
      right: 23%;
    }
  }
  
  .carousel_image img {
    width: 100%;
    height: 600px;
    padding: 2rem 0;
    object-fit: contain;
  }
  
  @media (max-width: 768px) {
    .parallax_item {
      animation: float 3s ease-in-out infinite;
  
      &:nth-child(1) {
        animation-delay: 0s;
      }
  
      &:nth-child(2) {
        animation-delay: 1s;
      }
  
      &:nth-child(3) {
        animation-delay: 2s;
      }
    }
  }
  
  @keyframes float {
    0% {
      transform: translateY(0) rotate(0deg);
    }
    50% {
      transform: translateY(-10px) rotate(3deg);
    }
    100% {
      transform: translateY(0) rotate(0deg);
    }
  }