.container {
    width: 100%;
    display: flex;

    a {
        color: var(--almost-white);
        text-transform: uppercase;
        transition: all 0.3s ease;

        &:hover {
            color: var(--light-purple);
        }

        &.active {
            color: red!important;
        }

    }
    
}
