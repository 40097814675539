// Медиа запросы
/*
0px - 600px:      Phone
600px - 900px:    Tablet portrait
900px - 1200px:   Tablet landscape
1200px - 1800px:  Desktop (обычные стили)

$breakpoint argument:
- phone
- tab-port
- tab-land
- desktop
*/

@mixin respond($breakpoint) {
    @if $breakpoint == phone {
        @media screen and (max-width: 37.5em) {
            @content;
        }
    }

    @if $breakpoint == tab-port {
        @media screen and (max-width: 56.25em) {
            @content;
        }
    }

    @if $breakpoint == tab-land {
        @media screen and (max-width: 75em) {
            @content;
        }
    }

    @if $breakpoint == desktop {
        @media screen and (min-width: 112.5em) {
            @content;
        }
    }
}
